/* <script> */
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
import { saveUserSettings } from '@/base/js/baseHelper';
import commonMixin from '@/common/js/commonMixin.js';

export default {
  mixins: [commonMixin],
  data () {
    return {
      states: {
        new: 'new', // int-value = null
        '-1': 'delete',
        0: 'draft',
        1: 'rework',
        2: 'review',
        4: 'reviewed1',
        5: 'reviewed2',
        6: 'active',
        7: 'future',
        8: 'over',
        9: 'neverActive',
        10: 'disabled',
        99: 'invalidated',
        100: 'more' // only for displaying groups
      },
      interestTypes: [
        'standard',
        'campaign', // Aktion
        'creditRating' // Bonität
      ],
      optimisationTypes: [
        'Bestzins',
        'Provisionsoptimiert'
      ],
      driveTypes: [
        '',
        'Autogas/LPG',
        'Benzin/Super',
        'Diesel',
        'Elektro',
        'Erdgas/CNG',
        'Ethanol/FFV/E85',
        'Hybrid (Benzin)',
        'Hybrid (Diesel)',
        'Plug-in-Hybrid (Benzin)',
        'Plug-in-Hybrid (Diesel)',
        'Wasserstoff'
      ],
      chassisTypes: [
        '',
        'Bus',
        'Cabrio/Roadster',
        'Kastenwagen',
        'Kleinwagen',
        'Kombi',
        'Limousine',
        'Pritchenwagen',
        'Sportwagen/Coupé',
        'SUV/Geländewagen/Pickup',
        'Van/Kleinbus'
      ],
      inExcludeModes: [
        { value: 0, class: 'v-chip--outlined grey lighten-4 error--text', text: this.$t('condAdmin.isInclude.exclude') },
        { value: 1, class: 'v-chip--outlined grey lighten-4 success--text', text: this.$t('condAdmin.isInclude.include') }
      ]
    };
  },
  computed: {
    currency () {
      return this.$store.state.condAdmin.countrySettings.currency;
    },
    currencyCent () {
      return this.$store.state.condAdmin.countrySettings.currencyCent;
    }
  },
  methods: {
    hasExamination () {
      return this.$store.state.condAdmin.orgSettings.publishing.examiners > 0;
    },
    hasActivatorRole () {
      return this.$store.state.condAdmin.orgSettings.publishing.activatorRole === true;
    },
    hasResidualValuesPart () {
      return this.$store.state.condAdmin.orgSettings.showResidualValues === true;
    },
    getOrgSetting (param) {
      return this.$store.state.condAdmin.orgSettings[param];
    },
    hasUserRight (askedRight, providerUid = null, brandList = null) {
      // check detailed userRight based on provider and brands
      let askedRestrictions = [];
      if (providerUid != null) {
        askedRestrictions.push({ key: 'provider', value: providerUid });
      }
      if (brandList != null) {
        askedRestrictions.push({ key: 'brand', value: brandList, linkType: 'AND' });
      }
      return this.checkUserRight(this.$store.state.condAdmin.userRights, askedRight, askedRestrictions);
    },
    getModifiedStateValue (item) {
      let stateValue = item.state;
      if (item.disabled === true) {
        return 10;
      }
      if (stateValue === 6) {
        if (item.validFrom != null && this.DateTimeHelper.isLater(item.validFrom /* than NOW */)) stateValue = 7;
        else if (item.validTo != null && this.DateTimeHelper.isEarlier(item.validTo /* than NOW */)) stateValue = 8;
        else if (item.isActive === false) stateValue = 9;
      }
      return stateValue;
    },
    getStateText (item = null, modify = true) {
      let stateValue = -1;
      if (item != null) {
        if (item.isDirty === true) return this.$t('condAdmin.state.unsaved');
        else if (item.feState != null) stateValue = item.feState;
        else stateValue = (modify ? this.getModifiedStateValue(item) : item.state);
      }
      switch (stateValue) {
        case 0: return this.$t('condAdmin.state.draft');
        case 1: return this.$t('condAdmin.state.rework');
        case 2: return this.$t('condAdmin.state.review');
        case 4: return this.$t('condAdmin.state.reviewed1');
        case 5: return this.$t('condAdmin.state.reviewed2');
        case 6: return this.$t('condAdmin.state.active');
        case 7: return this.$t('condAdmin.state.future');
        case 8: return this.$t('condAdmin.state.over');
        case 9: return this.$t('condAdmin.state.neverActive');
        case 10: return this.$t('condAdmin.state.disabled');
        case 99: return this.$t('condAdmin.state.invalidated');
        case 100: return this.$t('condAdmin.state.more');
      }
      return '';
    },
    getStateClass (item = null, modify = true) {
      let stateValue = -1;
      if (item != null) {
        if (item.isDirty === true) return 'v-chip--outlined grey error--text px-4';
        else if (item.feState != null) stateValue = item.feState;
        else stateValue = (modify ? this.getModifiedStateValue(item) : item.state);
      }
      switch (stateValue) {
        case 0: return 'v-chip--outlined grey lighten-4 px-4';
        case 1: return 'v-chip--outlined grey lighten-4 error--text';
        case 2: return 'v-chip--outlined grey lighten-4 info--text px-5';
        case 4: return 'v-chip--outlined grey lighten-4 info--text';
        case 5: return 'v-chip--outlined grey lighten-4 info--text px-4';
        case 6: return 'px-6';
        case 7: return 'v-chip--outlined grey lighten-4 success--text'; // text--darken-1';
        case 8: return 'error lighten-2';
        case 9: return 'v-chip--outlined grey lighten-4 grey--text';
        case 10: return 'error lighten-2';
        case 99: return 'error';
        case 100: return 'ml-2 px-4';
      }
      return '';
    },
    // -------------------------------------------------------------------------------------------------------
    getInterestTypeText (item = null, chipName = false) {
      let interestTypeValue = '';
      if (item != null) {
        interestTypeValue = item.indexName;
      }
      if (chipName === true) {
        switch (interestTypeValue) {
          case 'standard': return this.$t('condAdmin.interestType.standard.chipName');
          case 'campaign': return this.$t('condAdmin.interestType.campaign.chipName');
          case 'creditRating': return this.$t('condAdmin.interestType.creditRating.chipName');
        }
      } else {
        switch (interestTypeValue) {
          case 'standard': return this.$t('condAdmin.interestType.standard.name');
          case 'campaign': return this.$t('condAdmin.interestType.campaign.name');
          case 'creditRating': return this.$t('condAdmin.interestType.creditRating.name');
        }
      }
      return '';
    },
    // -------------------------------------------------------------------------------------------------------
    getInterestTypeClass (item = null, editMode = true, active = true) {
      let interestTypeValue = '';
      if (item != null) {
        interestTypeValue = item.indexName;
      }
      if (editMode) {
        switch (interestTypeValue) {
          case 'standard': return 'mt-0 ml-2 px-6';
          case 'campaign':
            if (active) {
              return 'blue lighten-3  mt-0 ml-2 px-4 info--text';
            } else {
              return 'grey lighten-4  mt-0 ml-2 px-4 grey--text';
            }
          case 'creditRating': return 'green lighten-3 mt-0 ml-2 px-4 info--text';
        }
      } else {
        switch (interestTypeValue) {
          case 'standard': return 'ml-2 px-6';
          case 'campaign':
            if (active) {
              return 'blue lighten-3  ml-2 px-6 info--text';
            } else {
              return 'grey lighten-4  mt-0 ml-2 px-4 grey--text';
            }
          case 'creditRating': return 'green lighten-3 ml-2 px-6 info--text';
        }
      }
      return '';
    },
    // -------------------------------------------------------------------------------------------------------
    async validateBeginDate (item) {
      let validFrom = item.validFrom;

      let beginConflict = item.versions.find((v) => (v.validFrom <= validFrom && (v.validTo == null || v.validTo >= validFrom)) && v.version !== item.version);
      if (beginConflict != null) {
        return this.$globals.Confirm.okCancelDlg(this.$t('condAdmin.common.dlghSave'), this.$t('condAdmin.common.dlgtSaveBeginDateConflict', { validFrom: this.formatBeginDate(validFrom) }));
      }
      return true;
    },
    async validateEndDate (item) {
      let validFrom = item.validFrom;
      let validTo = item.validTo;

      if (validTo == null) validTo = this.DateTimeHelper.INFINITEDATETIME;
      let endConflict = item.versions.find((v) => (v.validFrom >= validFrom && (v.validTo == null || v.validTo > validTo)) && v.version !== item.version);
      if (endConflict != null) {
        return this.$globals.Confirm.okCancelDlg(this.$t('condAdmin.common.dlghSave'), this.$t('condAdmin.common.dlgtSaveEndDateConflict', { validTo: this.formatEndDate(validTo) }));
      }
      return true;
    },
    getRangeText (min, max, unit, digits = null, noneValue = this.NULLVALUE) {
      if ((min == null || min === 0) && (max == null || max === 0)) {
        return noneValue;
      } else if (min == null || min === 0) {
        return this.$t('condAdmin.condition.to') + ' ' + this.$filters.formatNumber(max, digits, this.NULLVALUE) + ' ' + unit;
      } else if (max == null || max === 0) {
        return this.$t('condAdmin.condition.from') + ' ' + this.$filters.formatNumber(min, digits, this.NULLVALUE) + ' ' + unit;
      }
      return this.$filters.formatNumber(min, digits, this.NULLVALUE) + ' ' + unit + ' - ' + this.$filters.formatNumber(max, digits, this.NULLVALUE) + ' ' + unit;
    },
    getDurationsText (durations) {
      if (durations.length === 1) return durations[0];
      return durations[0] + ' - ' + durations[durations.length - 1];
    },
    getProductTypesText (types) {
      let arr = [];
      let str = '';
      for (let type of types) {
        let listType = this.$store.state.condAdmin.productTypeList.find((i) => i.uid === type);
        if (listType != null && !arr.includes(listType.name)) {
          str += ', ' + listType.name;
          arr.push(listType.name);
        }
      }
      return str.length > 0 ? str.substring(2) : this.$t('base.all');
    },
    getBrandsText (brands) {
      let str = '';
      for (let brand of brands) {
        if (brand != null) str += ', ' + brand;
      }
      return str.length > 0 ? str.substring(2) : this.$t('base.all');
    },
    getSubProductTypesText (subtypes) {
      let arr = [];
      let str = '';
      for (let subtype of subtypes) {
        for (let pt of this.$store.state.condAdmin.productTypeList) {
          let listType = pt.subProductTypes.find((i) => i.uid === subtype);
          if (listType != null && !arr.includes(listType.name)) {
            str += ', ' + listType.name;
            arr.push(listType.name);
          }
        }
      }
      return str.length > 0 ? str.substring(2) : this.NULLVALUE;
    },
    getSubProductTypeState (subtype) {
      for (let pt of this.$store.state.condAdmin.productTypeList) {
        let listType = pt.subProductTypes.find((i) => i.uid === subtype);
        if (listType != null) {
          return listType.state;
        }
      }
      return null;
    },
    async showHistory (processName, uid, headline) {
      // create ioData
      let ioData = {
        clientKey: this.$store.state.condAdmin.clientKey,
        uid: uid
      };

      // backend-request
      let processData = await this.$restClient.callProcess('condAdmin', processName, ioData, true);
      if (isProcessStateOK(processData)) {
        let hint = null;
        if (processData.processState.state === 'warning') {
          hint = this.$t(processData.processState.messages[0].message);
        }
        await this.$refs.historyDlg.show(
          headline,
          processData.ioData.history,
          hint
        );
      } else {
        this.$globals.Info.open(processData.processState.messages);
      }
      console.log(JSON.stringify(processData.ioData.history));
    },
    // ------------------------------------------------------------------------
    getUserTableSettings (tableName) {
      if (this.$store.state.condAdmin.userSettings[tableName] != null) {
        // take filter-values from userSettings
        if (this.$store.state.condAdmin.userSettings[tableName].filter != null) {
          for (let k of Object.keys(this.filter)) {
            if (this.$store.state.condAdmin.userSettings[tableName].filter[k] != null) {
              if (typeof this.$store.state.condAdmin.userSettings[tableName].filter[k] === 'object') {
                this.filter[k].value = this.$store.state.condAdmin.userSettings[tableName].filter[k].value;
              } else {
                this.filter[k].value = this.$store.state.condAdmin.userSettings[tableName].filter[k];
              }
            }
          }
        }
        // take shown columns from userSettings
        if (this.$store.state.condAdmin.userSettings[tableName].columns != null) {
          for (let c of this.availableColumns) {
            if (this.$store.state.condAdmin.userSettings[tableName].columns[c.value] != null) {
              c.active = this.$store.state.condAdmin.userSettings[tableName].columns[c.value];
            }
          }
        }
        if (this.$store.state.condAdmin.userSettings[tableName].footerPropItemsPerPage != null) {
          this.footerProps.itemsPerPage = this.$store.state.condAdmin.userSettings[tableName].footerPropItemsPerPage;
        }
      }
    },
    // ------------------------------------------------------------------------
    async saveUserTableSettings (tableName) {
      let columns = {};
      let filter = {};
      for (let c of this.availableColumns) {
        columns[c.value] = c.active;
      }
      for (let k of Object.keys(this.filter)) {
        if (this.filter[k].value != null) filter[k] = this.filter[k].value;
      }
      if (this.$store.state.condAdmin.userSettings[tableName] == null) {
        this.$store.state.condAdmin.userSettings[tableName] = {};
      }
      this.$store.state.condAdmin.userSettings[tableName].columns = columns;
      this.$store.state.condAdmin.userSettings[tableName].filter = filter;
      this.$store.state.condAdmin.userSettings[tableName].footerPropItemsPerPage = this.footerProps.itemsPerPage;
      await saveUserSettings('condAdmin');
    }
  }
};
// </script>
