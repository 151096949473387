import Vue from 'vue';
import { store } from '@/plugins/store';
import { isProcessStateOK, newMessage } from '@/base/js/ProcessDataHelper';
import { checkUserRight } from '@/base/js/baseHelper.js';

/* *****************************************************
 * CalcTypes
***************************************************** */
export const refreshCalcTypeList = async () => {
  store.state.condAdmin.calcTypeList = [];
  store.state.condAdmin.calcTypesLoading = true;
  let ioData = {
    clientKey: store.state.condAdmin.clientKey
  };

  let processData = await Vue.prototype.$restClient.callProcess('condAdmin', 'getCalcTypeList', ioData);
  if (isProcessStateOK(processData)) {
    store.state.condAdmin.calcTypeList = processData.ioData.calcTypeList;
    // for (let elem of store.state.condAdmin.calcTypeList) {
    //   prepareCalcTypeListElement(elem);
    // }
  } else {
    Vue.prototype.$globals.Info.open(processData.processState.messages);
  }
  store.state.condAdmin.calcTypesLoading = false;
};

export const getCalcTypeDurations = (calcType) => {
  // console.log('getCalcTypeDurations', calcType, '\n', JSON.stringify(store.state.condAdmin.calcTypeList));
  let ct = store.state.condAdmin.calcTypeList.find((i) => i.calcType === calcType);
  return ct.settings.durations.sort((a, b) => { return a - b; });
};

/* *****************************************************
 * ProductType
***************************************************** */
export const refreshProductTypeList = async () => {
  store.state.condAdmin.productTypeList = [];
  store.state.condAdmin.productTypesLoading = true;
  let ioData = {
    clientKey: store.state.condAdmin.clientKey
  };

  let processData = await Vue.prototype.$restClient.callProcess('condAdmin', 'getProductTypeList', ioData);
  if (isProcessStateOK(processData)) {
    store.state.condAdmin.productTypeList = processData.ioData.productTypeList;
  } else {
    Vue.prototype.$globals.Info.open(processData.processState.messages);
  }
  store.state.condAdmin.productTypesLoading = false;
};

/* *****************************************************
 * Product
***************************************************** */
export const refreshProductList = async (clientKey = null) => {
  store.state.condAdmin.productList = null;
  store.state.condAdmin.productsLoading = true;
  let ioData = {
    clientKey: clientKey || store.state.condAdmin.clientKey
  };

  let processData = await Vue.prototype.$restClient.callProcess('condAdmin', 'getProductList', ioData);
  if (isProcessStateOK(processData)) {
    store.state.condAdmin.productList = processData.ioData.productList || [];
  } else {
    Vue.prototype.$globals.Info.open(processData.processState.messages);
  }
  store.state.condAdmin.productsLoading = false;
  return processData.ioData.productList;
};

export const updateProductState = async (product, state) => {
  // check rights
  if (!hasUserRight('manageResidualValues', null, [product.brand])) {
    Vue.prototype.$globals.Info.open([newMessage('userError', Vue.prototype.$t('base.error.insufficientRights'))]);
    return;
  }
  let ioData = {
    clientKey: store.state.condAdmin.clientKey,
    productUid: product.uid,
    state: state
  };
  let processData = await Vue.prototype.$restClient.callProcess('condAdmin', 'updateProductState', ioData);
  if (isProcessStateOK(processData)) {
    product.state = state;
  } else {
    Vue.prototype.$globals.Info.open(processData.processState.messages);
  }
};

export const saveProduct = async (product) => {
  let ioData = {
    clientKey: store.state.condAdmin.clientKey,
    product: product
  };

  // validation
  let messages = [];
  if (product.productTypeUid == null) {
    messages.push({ type: 'userError', message: Vue.prototype.$t('base.missingMandatory') + Vue.prototype.$t('condAdmin.product.productType') });
  }
  if (messages.length > 0) {
    Vue.prototype.$globals.Info.open(messages);
    return null;
  } else {
    let processName = 'updateProduct';
    if (product.uid == null) processName = 'createProduct';
    let processData = await Vue.prototype.$restClient.callProcess('condAdmin', processName, ioData);
    if (isProcessStateOK(processData)) {
      if (processName === 'createProduct') {
        store.state.condAdmin.productList.push(processData.ioData.product);
      } else {
        let pIndex = store.state.condAdmin.productList.findIndex((p) => p.uid === processData.ioData.product.uid);
        store.state.condAdmin.productList[pIndex] = processData.ioData.product;
      }
      return processData.ioData.product;
    } else {
      Vue.prototype.$globals.Info.open(processData.processState.messages);
      return null;
    }
  }
};

export const productUniqueCheck = (product) => {
  let existing = store.state.condAdmin.productList.find((i) =>
    i.productTypeUid === product.productTypeUid &&
    i.brand === product.brand &&
    i.productName === product.productName &&
    i.productKey === product.productKey &&
    i.orderKey === product.orderKey &&
    i.chassis === product.chassis &&
    i.driveType === product.driveType &&
    i.serialId === product.serialId
  );
  if (existing != null) console.log('Existing Product:', JSON.stringify(existing));
  return existing;
};

export const copyProduct = (source = {}, isNew = false) => {
  return {
    id: source.id != null && !isNew ? source.id : null,
    uid: source.uid != null && !isNew ? source.uid : null,
    productTypeUid: source.productTypeUid != null ? source.productTypeUid : null,
    brand: source.brand != null ? source.brand : null,
    productName: source.productName != null ? source.productName : null,
    productKey: source.productKey != null ? source.productKey : null,
    orderKey: source.orderKey != null ? source.orderKey : null,
    chassis: source.chassis != null ? source.chassis : null,
    driveType: source.driveType != null ? source.driveType : null,
    serialId: source.serialId != null ? source.serialId : null,
    state: source.state != null ? source.state : 1,
    prv: source.prv != null && !isNew ? source.prv : null,
    inherited: source.inherited != null && !isNew ? source.inherited : false
  };
};

/* *****************************************************
 * ResidualValues
***************************************************** */
export const minRVValues = {
  de: 10.0,
  ch: 5.0
};

/* *****************************************************
 * common
***************************************************** */
export const formatLock = (lock) => {
  lock.timestamp = Vue.prototype.$filters.formatDateTime(lock.timestamp);
  return lock;
};

export const removeLock = async (type, uid, version = null) => {
  let ioData = {
    clientKey: store.state.condAdmin.clientKey,
    targetType: type,
    targetIdentifier: uid
  };
  if (version != null) ioData.targetVersion = version;

  let processData = await Vue.prototype.$restClient.callProcess('condAdmin', 'removeLock', ioData, true);
  Vue.prototype.$globals.Info.open(processData.processState.messages);
};

export const hasUserRight = (askedRight, providerUid = null, brandList = null) => {
  // check detailed userRight based on provider and brands
  let askedRestrictions = [];
  if (providerUid != null) {
    askedRestrictions.push({ key: 'provider', value: providerUid });
  }
  if (brandList != null) {
    askedRestrictions.push({ key: 'brand', value: brandList, linkType: 'AND' });
  }
  return checkUserRight(store.state.condAdmin.userRights, askedRight, askedRestrictions);
};
